import { Error404 } from 'components/Error/Error404';
import { useActiveUser } from 'hooks/useActiveUser';
import { NextPage } from 'next';

import { LayoutStatus, useLayout } from '../hooks/useLayout';

export const ErrorPage: NextPage = () => {
  const user = useActiveUser();

  useLayout({
    layout: user?.id ? 'Main' : 'Base',
    title: '404',
    layoutOptions: {
      header: LayoutStatus.CLEAR,
      sidebar: LayoutStatus.CLEAR,
      banner: LayoutStatus.CLEAR,
    },
  });

  return <Error404 />;
};

export default ErrorPage;
