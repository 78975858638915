import Button from 'elements/Button';
import Text from 'elements/Text';
import React, { FC } from 'react';
import { AlertTriangle } from 'react-feather';

export const Error404: FC = () => {
  return (
    <div className="flex items-center justify-center mt-12">
      <div className="w-full max-w-xl flex justify-center flex-col items-center">
        <div className="flex items-center mb-6">
          <AlertTriangle className="mr-3" size={52} />
          <Text.Heading>Oops!</Text.Heading>
        </div>
        <Text.Paragraph className="text-center text-3xl font-bold -mb-1">
          404
        </Text.Paragraph>
        <Text.Paragraph className="text-center fs21 font-bold mb-1">
          Page not found
        </Text.Paragraph>
        <Text.Paragraph className="text-center">
          Sorry, the page you are looking for does not exist.
        </Text.Paragraph>
        <Button className="mt-8" link={'/'}>
          Go to Dashboard
        </Button>
      </div>
    </div>
  );
};
